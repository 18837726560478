import * as React from "react";
import { Link } from "gatsby";

// import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function CanIBeHonest() {
  return (
    <Layout>
      <Helmet title="Can I Be Honest | Michael Weslander" />

      <h1>Can I Be Honest</h1>
      <h2>Michael Weslander</h2>
      <h3>Key: Am</h3>
      <h4>Tempo: 112</h4>
      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Can I be honest</p>
          <p>Without work this problem only grows</p>
          <p>And is it justice, to</p>
          <p>Wallow in an apprehensive show</p>
        </Verse>
        <PreChorus>
          <p>Am I</p>
          <p>Laying my heart bare.</p>
          <p>Fighting for a dream.</p>
          <p>Breaking from the cycle.</p>
          <p>Building up some steam.</p>
        </PreChorus>
        <Chorus>
          <p>Though I want to take it easy</p>
          <p>And you just want to leave</p>
          <p>If we can’t be bold right now</p>
          <p>Will we ever be?</p>
        </Chorus>
        <Verse>
          <p>Can I be honest</p>
          <p>Look around and what is there to show</p>
          <p>I be here talking</p>
          <p>I've said these words a thousand times before</p>
        </Verse>
        <PreChorus>
          <p>Am I</p>
          <p>Laying my heart bare.</p>
          <p>Fighting for a dream.</p>
          <p>Breaking from the cycle.</p>
          <p>Building up some steam.</p>
        </PreChorus>
        <Chorus>
          <p>Though I want to take it easy</p>
          <p>And you just want to leave</p>
          <p>If we can’t be bold right now</p>
          <p>Will we ever be?</p>
        </Chorus>
        <Chorus>
          <p>Though I want to take it easy</p>
          <p>And you just want to leave</p>
          <p>If we can’t be bold right now</p>
          <p>Will we ever be?</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
